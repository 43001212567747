import React, { useState, useEffect } from 'react';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import TableSortLabel from '@material-ui/core/TableSortLabel';
import Paper from '@material-ui/core/Paper';
import { Button } from '@paljs/ui/Button';
import { InputGroup } from '@paljs/ui/Input';
import DataTable from 'react-data-table-component';
import { ActivityOfUser } from '../../actions/activityAction';
import { useLocation } from '@reach/router';
import LoadingSpinner from '../loader';
import Export from '../../components/export_csv/export';

const Users = () => {
  const location = useLocation();
  const url = decodeURI(location.search);
  const params = new URLSearchParams(url);
  let idfromURL = params.get('id');

  const [rows, setRows] = useState([]);
  const [isLoading, showLoader] = useState(true);
  const [originalRows, setOriginalRows] = useState([]);
  const [filter, setFilter] = useState([]);

  console.log('url response', idfromURL);

  const tableColumns = [
    {
      name: 'S.No.',
      sortable: false,
      grow: 0.2,
      cell: (row, index, column, id) => {
        return <div>{index + 1}</div>;
      },
    },
    {
      name: 'Action',
      selector: (row) => row.type,
      sortable: true,
    },
    {
      name: 'Date',
      sortable: true,
      cell: (row) => {
        const event = new Date(row.when);
        const event1 = event.toDateString();
        return <div>{event1}</div>;
      },
    },
    {
      name: 'Activity',
      selector: (row) => row.message,
      sortable: true,
      wrap: true,
      grow: 3,
    },
  ];

  useEffect(() => {
    try {
      const response = ActivityOfUser(`${idfromURL}`);
      const data = response?.data;
      console.log('getting the details ', data);
      setRows(data);
      showLoader(false);
      setOriginalRows(data);
    } catch (error) {
      // setError('Something went wrong!');
      showLoader(false);
    }
  }, []);
  const handleInputChange = (event: any) => {
    const value = event.target.value.trim();
    const temp_rows = originalRows.filter((e) => JSON.stringify(e).indexOf(value.toLowerCase()) >= 0);
    setRows(temp_rows);
  };

  const actionsMemo = React.useMemo(() => <Export rows={rows} />, [rows]);

  const byUser = (
    <>
      <div style={{ display: 'flex', justifyContent: 'space-between' }}>
        <InputGroup size="Small" status="Info">
          <input type="text" placeholder="Search..." onChange={(event) => handleInputChange(event)} />
        </InputGroup>
        <select
          onChange={(event) => {
            setFilter(event.target.value);
            console.log('from select ', event.target.value);
            const filter = originalRows.filter((eh) => eh.type === event.target.value);
            if (event.target.value !== 'All') {
              setRows(filter);
            } else {
              setRows(originalRows);
            }
          }}
        >
          <option value="All">Filter By Activity Type</option>
          <option value="ADD">ADD</option>
          <option value="DELETE">DELETE</option>
          <option value="JOIN">JOIN</option>
          <option value="UPDATE">UPDATE</option>
        </select>
      </div>
      <div
        style={{
          textAlign: 'center',
          backgroundColor: 'whitesmoke',
          color: 'black',
          padding: '18px',
          fontSize: '25px',
        }}
      >
        Actions Performed By User
      </div>
      <DataTable
        title="By User Activity List"
        columns={tableColumns}
        data={rows}
        pagination
        paginationRowsPerPageOptions={[10, 20, 50, 100, 200, 500]}
        paginationPerPage={100}
        striped
        fixedHeader
        dense
        highlightOnHover
        actions={actionsMemo}
      />
    </>
  );
  return <>{isLoading ? <LoadingSpinner message={'loading By User Activity Data'} /> : byUser}</>;
};
export default Users;
